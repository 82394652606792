var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.networkError
    ? _c("no-content-component", { attrs: { message: _vm.noContentMessage } })
    : _c(
        "v-container",
        [
          _c(
            "div",
            { staticClass: "mt-4 mb-6", staticStyle: { "font-size": "25px" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { color: "black", icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.renderNow = false
                      _vm.$router.push("/teachers/sms-email")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "36" } }, [
                    _vm._v("arrow_back"),
                  ]),
                ],
                1
              ),
              _vm._v(
                " " +
                  _vm._s(!_vm.$language ? "Create Bulk " : "") +
                  " " +
                  _vm._s(_vm.elementTypeCased) +
                  " " +
                  _vm._s(!_vm.$language ? " " : "তৈরি করুন") +
                  " "
              ),
            ],
            1
          ),
          _c("submission-alert-message", {
            ref: "banner",
            staticClass: "mb-3",
            attrs: { message: _vm.bannerMessage },
            model: {
              value: _vm.showBannerCondition,
              callback: function ($$v) {
                _vm.showBannerCondition = $$v
              },
              expression: "showBannerCondition",
            },
          }),
          _c(
            "v-card",
            { attrs: { height: "100%", flat: "", outlined: "" } },
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 ml-6 pb-4",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "22px",
                                color: "#202020",
                                "font-weight": "500",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "Course List"
                                      : "কোর্সের তালিকা"
                                  )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pr-2",
                              staticStyle: {
                                "overflow-y": "auto",
                                "overflow-x": "hidden",
                                "max-height": "70vh",
                                "max-width": "80vw",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "d-flex align-center justify-left ml-6",
                                  staticStyle: {
                                    "font-size": "16px",
                                    "font-weight": "300",
                                    color: "#707070",
                                  },
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c("v-checkbox", {
                                    model: {
                                      value:
                                        _vm.requestObject.is_send_to_student,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.requestObject,
                                          "is_send_to_student",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "requestObject.is_send_to_student",
                                    },
                                  }),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language
                                            ? "Students'"
                                            : "শিক্ষার্থীর"
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.elementType == "sms"
                                            ? !_vm.$language
                                              ? "Number"
                                              : "নাম্বার"
                                            : !_vm.$language
                                            ? "Email"
                                            : "ইমেইল"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("v-checkbox", {
                                    staticStyle: { "padding-left": "2%" },
                                    model: {
                                      value:
                                        _vm.requestObject.is_send_to_guardian,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.requestObject,
                                          "is_send_to_guardian",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "requestObject.is_send_to_guardian",
                                    },
                                  }),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language
                                            ? "Guardians'"
                                            : "অভিবাবকের"
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.elementType == "sms"
                                            ? !_vm.$language
                                              ? "Number"
                                              : "নাম্বার"
                                            : !_vm.$language
                                            ? "Email"
                                            : "ইমেইল"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panels",
                                {
                                  staticClass: "ml-6 pr-6",
                                  attrs: {
                                    multiple: "",
                                    flat: "",
                                    focusable: "",
                                  },
                                },
                                _vm._l(_vm.courses, function (course, i) {
                                  return _c(
                                    "v-expansion-panel",
                                    {
                                      key: i,
                                      staticClass: "mt-2",
                                      staticStyle: {
                                        "box-shadow": "none !important",
                                        "min-height": "60px",
                                        border: "solid 1px #b7b7b7",
                                        "border-radius": "5px",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.coursesExpand(
                                            course.course_id,
                                            course.partner_code
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { color: "white" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-checkbox", {
                                                staticClass:
                                                  "pb-0 mb-0 flex-grow-0",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.courseSelection(
                                                      course.course_id
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.active[
                                                      course.course_id
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.active,
                                                      course.course_id,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "active[course.course_id]",
                                                },
                                              }),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pt-7 font-weight-bold",
                                                  attrs: { cols: "auto" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      course.course_title
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                              _c("v-spacer"),
                                              course.is_organization
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-4 pt-7 pr-4 font-weight-medium",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          course.num_org_batches
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            !_vm.$language
                                                              ? "Batch"
                                                              : "ব্যাচ"
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-4 pt-7 pr-4 font-weight-medium",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          course.num_batches
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            !_vm.$language
                                                              ? "Batch"
                                                              : "ব্যাচ"
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-expansion-panel-content",
                                        {
                                          staticStyle: {
                                            "margin-top": "16px",
                                            padding: "0%",
                                          },
                                        },
                                        [
                                          !_vm.batches[course.course_id]
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center",
                                                },
                                                [
                                                  _c("v-progress-circular", {
                                                    attrs: {
                                                      indeterminate: "",
                                                      color: "#0099dc",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-card",
                                                { attrs: { outlined: "" } },
                                                _vm._l(
                                                  _vm.batches[course.course_id],
                                                  function (batch, j) {
                                                    return _c(
                                                      "div",
                                                      { key: j },
                                                      [
                                                        _c(
                                                          "v-expansion-panel",
                                                          {
                                                            attrs: {
                                                              readonly:
                                                                batch.num_students ==
                                                                0,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.batchExpand(
                                                                  batch.batch_id,
                                                                  batch.course_id,
                                                                  batch.partner_code
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-expansion-panel-header",
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pb-0 mb-0 flex-grow-0",
                                                                    attrs: {
                                                                      disabled:
                                                                        batch.num_students ==
                                                                        0,
                                                                      "input-value":
                                                                        _vm
                                                                          .active[
                                                                          batch
                                                                            .course_id
                                                                        ],
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.batchSelection(
                                                                            batch.batch_id,
                                                                            batch.course_id
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .activeBatch[
                                                                          batch
                                                                            .course_id
                                                                        ][
                                                                          batch
                                                                            .batch_id
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .activeBatch[
                                                                              batch
                                                                                .course_id
                                                                            ],
                                                                            batch.batch_id,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "\n                              activeBatch[batch.course_id][batch.batch_id]\n                            ",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-bold",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          batch.batch_title
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-medium d-flex justify-end",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .selectedStudentCount[
                                                                          batch
                                                                            .batch_id
                                                                        ]
                                                                      ) +
                                                                        "/" +
                                                                        _vm._s(
                                                                          batch.num_students
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          !_vm.$language
                                                                            ? "Students"
                                                                            : "শিক্ষার্থী"
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-expansion-panel-content",
                                                              [
                                                                !_vm.studentCondition(
                                                                  batch.course_id,
                                                                  batch.batch_id
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-progress-circular",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                indeterminate:
                                                                                  "",
                                                                                color:
                                                                                  "#0099dc",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      _vm._l(
                                                                        _vm
                                                                          .students[
                                                                          batch
                                                                            .course_id
                                                                        ][
                                                                          batch
                                                                            .batch_id
                                                                        ],
                                                                        function (
                                                                          student,
                                                                          j
                                                                        ) {
                                                                          return _c(
                                                                            "student-contact-card",
                                                                            {
                                                                              key: j,
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  "message-type":
                                                                                    _vm.elementType,
                                                                                  student:
                                                                                    {
                                                                                      ..._vm
                                                                                        .student_info[
                                                                                        student
                                                                                      ],
                                                                                      id: student,
                                                                                    },
                                                                                  course:
                                                                                    batch.course_id,
                                                                                  batch:
                                                                                    batch.batch_id,
                                                                                  active:
                                                                                    `${student}#${batch.batch_id}#${batch.course_id}` in
                                                                                    _vm.selectedItems,
                                                                                },
                                                                              on: {
                                                                                check:
                                                                                  _vm.handleInsertion,
                                                                              },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.screenSize > 1280
                            ? _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    "padding-top": "4%",
                                    "padding-left": "4%",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "11" } },
                                        [
                                          _vm.titleFocused === true &&
                                          (_vm.title.trim().length < 5 ||
                                            _vm.title.trim().length > 100)
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "red",
                                                    "font-size": "12px",
                                                    position: "relative",
                                                    top: "88px",
                                                    left: "8px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.elementType ===
                                                          "email"
                                                          ? !_vm.$language
                                                            ? "The subject must be between 5 and 100 character"
                                                            : "সাবজেক্ট ৫ থেকে ১০০ অক্ষরের মধ্যে হতে হবে"
                                                          : !_vm.$language
                                                          ? " "
                                                          : " "
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.elementType === "email"
                                            ? _c("v-text-field", {
                                                attrs: {
                                                  outlined: "",
                                                  "auto-grow": "",
                                                  height: "70",
                                                  "clear-icon": "close",
                                                  label: !_vm.$language
                                                    ? _vm.elementTypeCased.concat(
                                                        " Subject "
                                                      )
                                                    : _vm.elementTypeCased.concat(
                                                        " সাবজেক্ট "
                                                      ),
                                                  placeholder: !_vm.$language
                                                    ? "Write your email subject.."
                                                    : "ইমেইলের সাবজেক্ট লিখুন",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    _vm.titleFocused = true
                                                  },
                                                },
                                                model: {
                                                  value: _vm.title,
                                                  callback: function ($$v) {
                                                    _vm.title =
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                  },
                                                  expression: "title",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticStyle: { position: "relative" },
                                          attrs: { cols: "11" },
                                        },
                                        [
                                          _c("quill-editor", {
                                            ref: "how-it-works",
                                            staticStyle: { height: "26vh" },
                                            attrs: {
                                              options: {
                                                ..._vm.editorOptions,
                                                placeholder: "...............",
                                                scrollingContainer:
                                                  _vm.$refs["how-it-works"],
                                              },
                                            },
                                            on: {
                                              focus: function ($event) {
                                                _vm.textFocused = true
                                              },
                                            },
                                            model: {
                                              value: _vm.text,
                                              callback: function ($$v) {
                                                _vm.text =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "text",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "quill-label" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.elementType === "email"
                                                      ? !_vm.$language
                                                        ? `Email Body`
                                                        : "ইমেইল লিখুন"
                                                      : !_vm.$language
                                                      ? `Message`
                                                      : "মেসেজ"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm.textFocused === true &&
                                          !(_vm.ruleBody === true)
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "red",
                                                    "font-size": "12px",
                                                    "margin-top": "46px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.ruleBody) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-col"),
                                              _c(
                                                "v-col",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "60px",
                                                    display: "flex",
                                                    "justify-content": "end",
                                                  },
                                                  attrs: { cols: "8" },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "red",
                                                        width: "100px",
                                                        rounded: "",
                                                        depressed: "",
                                                        outlined: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.$router.push(
                                                            "/teachers/sms-email"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            !_vm.$language
                                                              ? "Cancel"
                                                              : "বাতিল"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ml-4",
                                                      attrs: {
                                                        loading: _vm.saving,
                                                        color: "primary",
                                                        width: "100px",
                                                        depressed: "",
                                                        rounded: "",
                                                      },
                                                      on: {
                                                        click: _vm.postSMSEmail,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            !_vm.$language
                                                              ? "Share"
                                                              : "শেয়ার করুন"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.screenSize <= 1280
                        ? _c(
                            "v-row",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-2",
                                      attrs: { outlined: "", color: "warning" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push(
                                            "/teachers/sms-email"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language ? "Cancel" : "বাতিল"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-2",
                                      attrs: {
                                        color: "primary",
                                        disabled: _vm.isChecked == 0,
                                      },
                                      on: { click: _vm.switchTab },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language
                                              ? "Next"
                                              : "পরবর্তী ধাপ"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: {
                                "padding-top": "4%",
                                "padding-left": "4%",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "11" } },
                                    [
                                      _vm.titleFocused === true &&
                                      (_vm.title.trim().length < 5 ||
                                        _vm.title.trim().length > 100)
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "font-size": "12px",
                                                position: "relative",
                                                top: "88px",
                                                left: "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.elementType === "email"
                                                      ? !_vm.$language
                                                        ? "The subject must be between 5 and 100 character"
                                                        : "সাবজেক্ট ৫ থেকে ১০০ অক্ষরের মধ্যে হতে হবে"
                                                      : !_vm.$language
                                                      ? " "
                                                      : " "
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.elementType === "email"
                                        ? _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              "auto-grow": "",
                                              height: "70",
                                              "clear-icon": "close",
                                              label: !_vm.$language
                                                ? _vm.elementTypeCased.concat(
                                                    " Subject "
                                                  )
                                                : _vm.elementTypeCased.concat(
                                                    " সাবজেক্ট "
                                                  ),
                                              placeholder: !_vm.$language
                                                ? "Write your email subject.."
                                                : "ইমেইলের সাবজেক্ট লিখুন",
                                            },
                                            on: {
                                              focus: function ($event) {
                                                _vm.titleFocused = true
                                              },
                                            },
                                            model: {
                                              value: _vm.title,
                                              callback: function ($$v) {
                                                _vm.title =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "title",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: { position: "relative" },
                                      attrs: { cols: "11" },
                                    },
                                    [
                                      _c("quill-editor", {
                                        ref: "how-it-works",
                                        staticStyle: { height: "26vh" },
                                        attrs: {
                                          options: {
                                            ..._vm.editorOptions,
                                            placeholder:
                                              ".....................",
                                            scrollingContainer:
                                              _vm.$refs["how-it-works"],
                                          },
                                        },
                                        on: {
                                          focus: function ($event) {
                                            _vm.textFocused = true
                                          },
                                        },
                                        model: {
                                          value: _vm.text,
                                          callback: function ($$v) {
                                            _vm.text =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "text",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "quill-label" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.elementType === "email"
                                                  ? !_vm.$language
                                                    ? `Email Body`
                                                    : "ইমেইল লিখুন"
                                                  : !_vm.$language
                                                  ? `Message`
                                                  : "মেসেজ"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm.textFocused === true &&
                                      !(_vm.ruleBody === true)
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "font-size": "12px",
                                                "margin-top": "46px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.ruleBody) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col"),
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: {
                                                "margin-top": "60px",
                                                display: "flex",
                                                "justify-content": "end",
                                              },
                                              attrs: { cols: "8" },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "red",
                                                    width: "100px",
                                                    rounded: "",
                                                    depressed: "",
                                                    outlined: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.tab = 0
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        !_vm.$language
                                                          ? "Back"
                                                          : "ফিরে যান"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-4",
                                                  attrs: {
                                                    color: "primary",
                                                    width: "100px",
                                                    depressed: "",
                                                    rounded: "",
                                                  },
                                                  on: {
                                                    click: _vm.postSMSEmail,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        !_vm.$language
                                                          ? "Share"
                                                          : "শেয়ার করুন"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }