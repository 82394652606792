import { render, staticRenderFns } from "./CreateSmsAndEmail.vue?vue&type=template&id=43cf5f88&scoped=true"
import script from "./CreateSmsAndEmail.vue?vue&type=script&lang=js"
export * from "./CreateSmsAndEmail.vue?vue&type=script&lang=js"
import style0 from "./CreateSmsAndEmail.vue?vue&type=style&index=0&id=43cf5f88&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43cf5f88",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/fluent/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43cf5f88')) {
      api.createRecord('43cf5f88', component.options)
    } else {
      api.reload('43cf5f88', component.options)
    }
    module.hot.accept("./CreateSmsAndEmail.vue?vue&type=template&id=43cf5f88&scoped=true", function () {
      api.rerender('43cf5f88', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "portals/teachers-portal/src/routes/sms-and-email/CreateSmsAndEmail.vue"
export default component.exports