<template>
  <v-card
    style="width: 100%; height: auto; min-height: 110px"
    class="mt-4 pl-6 pt-3"
    flat
    outlined
  >
    <v-row no-gutters style="height: 52%">
      <v-checkbox
        :input-value="active"
        class="mt-0"
        @click="toggleCheckbox()"
      ></v-checkbox>
      <v-col style="font-size: 12px; padding: 0px" cols="10">
        <v-row no-gutters class="pl-1 pt-1">
          <v-avatar color="primary" size="24"
            >
            <span class="white--text">
              {{ getImageFromTitle(student.name) }}
            </span>
          </v-avatar>
          <div class="pt-1 ml-1">{{ student.name }}</div>
        </v-row>
        <v-row style="font-size: 12px; margin-left: 20px">
          <img class="filter-black" :src="smsOrEmailIcon" />
          <span
            v-if="messageType === 'sms'"
            style="margin-top: 4px; margin-left: 4px"
            >{{ student.phone }}</span
          >
          <span
            v-else-if="messageType === 'email'"
            style="margin-top: 4px; margin-left: 4px"
            >{{ student.email }}</span
          >
        </v-row>
        <v-row
          v-for="(person, i) in student.guardian"
          :key="i"
          class="xl:text-sm lg:text-sm md:text-sm sm:text-sm ml-5"
        >
          <img class="filter-black" :src="smsOrEmailIcon" />
          <span v-if="messageType === 'sms'" class="mt-1 ml-1">{{
            person.number
          }}</span>
          <span v-else-if="messageType === 'email'" class="mt-1 ml-1"
            >{{ person.email }}<span class="ml-2 guardian-symbol">G</span></span
          >
        </v-row>
        <div class="pb-4"></div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "StudentContactCard",
  props: {
    messageType: {
      required: true
    },
    course: {
      required: true
    },
    batch: {
      required: true
    },

    student: {
      required: true
    },
    color: {
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      student_object: {},
      selectedValue: null,
      newArray: [],
      selectedObject: {},
      selectedStudentsList: [],
      selectedStudents: false,
      identifier: ""
    };
  },
  emits: ["selectedStudentsDetails"],
  computed: {
    smsOrEmailIcon() {
      return this.messageType === "email"
        ? require("@ecf/global/assets/img/icons/email_icon.svg")
        : require("@ecf/global/assets/img/icons/sms_icon.svg");
    },
    hasAvatar() {
      return !(
        this.student.avatar === undefined ||
        this.student.avatar === null ||
        this.student.avatar === ""
      );
    }
  },
  created() {
    this.identifier = `${this.student.id}#${this.batch}#${this.course}`;
  },
  methods: {
    getImageFromTitle(title) {
      let words = title.split(" ");
      return words
        .map(w => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    },
    toggleCheckbox(student_id) {
      // this.selectedObject[this.selectedValue] = this.selectedStudents;
      // this.selectedObject[this.selectedValue] =
      //   !this.selectedObject[this.selectedValue];
      // this.selectedStudentsList.push(this.selectedObject);
      // if (this.messageType === "sms") {
      //   delete this.student["studentEmail"];
      //   delete this.student["guardianEmail"];
      // } else {
      //   delete this.student["studentPhone"];
      //   delete this.student["guardianPhone"];
      // }
      // if (this.selectedValue.length > 0) {
      //   this.student_object = {
      //     ...this.course,
      //     ...this.batch,
      //     ...this.student,
      //   };
      //   this.$emit("selectedStudentsDetails", this.student_object);
      // } else {
      //   this.$emit("selectedStudentsDetails", student_id);
      // }
      this.$emit("check", this.identifier);
    }
  }
};
</script>

<style scoped>
.guardian-symbol {
  background-color: #707070;
  height: 20px;
  width: 20px;
  margin-top: 2px;
  border-radius: 4px;
  padding: 4px;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.filter-primary {
  filter: invert(38%) sepia(72%) saturate(1310%) hue-rotate(169deg)
    brightness(99%) contrast(101%);
}

.filter-orange {
  filter: invert(45%) sepia(74%) saturate(2367%) hue-rotate(346deg)
    brightness(100%) contrast(98%);
}

.filter-black {
  filter: invert(0%) sepia(4%) saturate(4%) hue-rotate(25deg) brightness(106%)
    contrast(100%);
}

.inner-circle {
  width: 32px;
  height: 32px;
  padding-left: 10px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  line-height: 30px;
  border-color: black;
  background-color: #eeeeee;
  color: black;
  font-size: 10px;
}
</style>
