var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mt-4 pl-6 pt-3",
      staticStyle: { width: "100%", height: "auto", "min-height": "110px" },
      attrs: { flat: "", outlined: "" },
    },
    [
      _c(
        "v-row",
        { staticStyle: { height: "52%" }, attrs: { "no-gutters": "" } },
        [
          _c("v-checkbox", {
            staticClass: "mt-0",
            attrs: { "input-value": _vm.active },
            on: {
              click: function ($event) {
                return _vm.toggleCheckbox()
              },
            },
          }),
          _c(
            "v-col",
            {
              staticStyle: { "font-size": "12px", padding: "0px" },
              attrs: { cols: "10" },
            },
            [
              _c(
                "v-row",
                { staticClass: "pl-1 pt-1", attrs: { "no-gutters": "" } },
                [
                  _c("v-avatar", { attrs: { color: "primary", size: "24" } }, [
                    _c("span", { staticClass: "white--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getImageFromTitle(_vm.student.name)) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "pt-1 ml-1" }, [
                    _vm._v(_vm._s(_vm.student.name)),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticStyle: { "font-size": "12px", "margin-left": "20px" } },
                [
                  _c("img", {
                    staticClass: "filter-black",
                    attrs: { src: _vm.smsOrEmailIcon },
                  }),
                  _vm.messageType === "sms"
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-top": "4px",
                            "margin-left": "4px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.student.phone))]
                      )
                    : _vm.messageType === "email"
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-top": "4px",
                            "margin-left": "4px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.student.email))]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._l(_vm.student.guardian, function (person, i) {
                return _c(
                  "v-row",
                  {
                    key: i,
                    staticClass:
                      "xl:text-sm lg:text-sm md:text-sm sm:text-sm ml-5",
                  },
                  [
                    _c("img", {
                      staticClass: "filter-black",
                      attrs: { src: _vm.smsOrEmailIcon },
                    }),
                    _vm.messageType === "sms"
                      ? _c("span", { staticClass: "mt-1 ml-1" }, [
                          _vm._v(_vm._s(person.number)),
                        ])
                      : _vm.messageType === "email"
                      ? _c("span", { staticClass: "mt-1 ml-1" }, [
                          _vm._v(_vm._s(person.email)),
                          _c("span", { staticClass: "ml-2 guardian-symbol" }, [
                            _vm._v("G"),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              _c("div", { staticClass: "pb-4" }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }