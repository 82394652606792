<template>
  <no-content-component
    v-if="networkError"
    :message="noContentMessage"
  ></no-content-component>
  <v-container v-else>
    <div style="font-size: 25px" class="mt-4 mb-6">
      <v-btn
        class="mr-2"
        color="black"
        icon
        @click="
          renderNow = false;
          $router.push('/teachers/sms-email');
        "
      >
        <v-icon size="36">arrow_back</v-icon>
      </v-btn>
      {{ !$language ? "Create Bulk " : "" }} {{ elementTypeCased }}
      {{ !$language ? " " : "তৈরি করুন" }}
    </div>
    <submission-alert-message
      ref="banner"
      v-model="showBannerCondition"
      :message="bannerMessage"
      class="mb-3"
    ></submission-alert-message>
    <v-card height="100%" flat outlined>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <!-- <div
            class="mt-4 ml-6 pb-4"
            style="font-size: 18px; color: #202020; font-weight: 500"
          >
            Course List
          </div> -->
          <v-row no-gutters class="mt-4 ml-6 pb-4">
            <span style="font-size: 22px; color: #202020; font-weight: 500">
              {{ !$language ? "Course List" : "কোর্সের তালিকা" }}</span
            >
            <!-- {{ courses[0] }} -->
          </v-row>
          <v-row>
            <v-col
              style="
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 70vh;
                max-width: 80vw;
              "
              class="pr-2"
            >
              <v-row
                class="d-flex align-center justify-left ml-6"
                style="font-size: 16px; font-weight: 300; color: #707070"
                no-gutters
              >
                <v-checkbox
                  v-model="requestObject.is_send_to_student"
                ></v-checkbox>
                <div>
                  {{ !$language ? "Students'" : "শিক্ষার্থীর" }}
                  {{
                    elementType == "sms"
                      ? !$language
                        ? "Number"
                        : "নাম্বার"
                      : !$language
                      ? "Email"
                      : "ইমেইল"
                  }}
                </div>

                <v-checkbox
                  v-model="requestObject.is_send_to_guardian"
                  style="padding-left: 2%"
                ></v-checkbox>
                <div>
                  {{ !$language ? "Guardians'" : "অভিবাবকের" }}
                  {{
                    elementType == "sms"
                      ? !$language
                        ? "Number"
                        : "নাম্বার"
                      : !$language
                      ? "Email"
                      : "ইমেইল"
                  }}
                </div>
              </v-row>
              <v-expansion-panels multiple flat focusable class="ml-6 pr-6">
                <v-expansion-panel
                  v-for="(course, i) in courses"
                  :key="i"
                  class="mt-2"
                  style="
                    box-shadow: none !important;
                    min-height: 60px;
                    border: solid 1px #b7b7b7;
                    border-radius: 5px;
                  "
                  @click="coursesExpand(course.course_id, course.partner_code)"
                >
                  <v-expansion-panel-header
                    style="font-size: 14px"
                    color="white"
                  >
                    <v-row>
                      <v-checkbox
                        v-model="active[course.course_id]"
                        class="pb-0 mb-0 flex-grow-0"
                        @click.stop="courseSelection(course.course_id)"
                      ></v-checkbox>
                      <v-col cols="auto" class="pt-7 font-weight-bold"
                        >{{ course.course_title }}
                      </v-col>
                      <v-spacer></v-spacer>
                      <span
                        v-if="course.is_organization"
                        class="ml-4 pt-7 pr-4 font-weight-medium"
                        >{{ course.num_org_batches }}
                        {{ !$language ? "Batch" : "ব্যাচ" }}</span
                      >
                      <span v-else class="ml-4 pt-7 pr-4 font-weight-medium"
                        >{{ course.num_batches }}
                        {{ !$language ? "Batch" : "ব্যাচ" }}</span
                      >
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    style="margin-top: 16px; padding: 0%"
                  >
                    <div
                      v-if="!batches[course.course_id]"
                      class="d-flex justify-center"
                    >
                      <v-progress-circular indeterminate color="#0099dc">
                      </v-progress-circular>
                    </div>
                    <v-card v-else outlined>
                      <div
                        v-for="(batch, j) in batches[course.course_id]"
                        :key="j"
                      >
                        <v-expansion-panel
                          :readonly="batch.num_students == 0"
                          @click="
                            batchExpand(
                              batch.batch_id,
                              batch.course_id,
                              batch.partner_code
                            )
                          "
                        >
                          <v-expansion-panel-header>
                            <v-checkbox
                              v-model="
                                activeBatch[batch.course_id][batch.batch_id]
                              "
                              class="pb-0 mb-0 flex-grow-0"
                              :disabled="batch.num_students == 0"
                              :input-value="active[batch.course_id]"
                              @click.stop="
                                batchSelection(batch.batch_id, batch.course_id)
                              "
                            ></v-checkbox>
                            <div
                              class="font-weight-bold"
                              style="font-size: 12px"
                            >
                              {{ batch.batch_title }}
                            </div>
                            <span class="font-weight-medium d-flex justify-end"
                              >{{ selectedStudentCount[batch.batch_id] }}/{{
                                batch.num_students
                              }}
                              {{ !$language ? "Students" : "শিক্ষার্থী" }}</span
                            >
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div
                              v-if="
                                !studentCondition(
                                  batch.course_id,
                                  batch.batch_id
                                )
                              "
                              class="d-flex justify-center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0099dc"
                              >
                              </v-progress-circular>
                            </div>
                            <div v-else>
                              <!-- <student-contact-card
                                v-for="(student, j) in students[
                                  batch.course_id
                                ][batch.batch_id]"
                                :key="j"
                                color="primary"
                                :message-type="elementType"
                                :student="{
                                  ...student_info[student],
                                  id: student,
                                }"
                                :course="batch.course_id"
                                :batch="batch.batch_id"
                                :active="
                                  activeBatch[batch.course_id][batch.batch_id]
                                "
                                @check="handleInsertion"
                              ></student-contact-card> -->
                              <student-contact-card
                                v-for="(student, j) in students[
                                  batch.course_id
                                ][batch.batch_id]"
                                :key="j"
                                color="primary"
                                :message-type="elementType"
                                :student="{
                                  ...student_info[student],
                                  id: student
                                }"
                                :course="batch.course_id"
                                :batch="batch.batch_id"
                                :active="
                                  `${student}#${batch.batch_id}#${batch.course_id}` in
                                    selectedItems
                                "
                                @check="handleInsertion"
                              ></student-contact-card>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </div>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <!-- <v-col cols="1"></v-col> -->

            <v-col
              v-if="screenSize > 1280"
              style="padding-top: 4%; padding-left: 4%"
            >
              <v-row>
                <v-col cols="11">
                  <div
                    v-if="
                      titleFocused === true &&
                        (title.trim().length < 5 || title.trim().length > 100)
                    "
                    style="
                      color: red;
                      font-size: 12px;
                      position: relative;
                      top: 88px;
                      left: 8px;
                    "
                  >
                    {{
                      elementType === "email"
                        ? !$language
                          ? "The subject must be between 5 and 100 character"
                          : "সাবজেক্ট ৫ থেকে ১০০ অক্ষরের মধ্যে হতে হবে"
                        : !$language
                        ? " "
                        : " "
                    }}
                  </div>
                  <v-text-field
                    v-if="elementType === 'email'"
                    v-model.trim="title"
                    outlined
                    auto-grow
                    height="70"
                    clear-icon="close"
                    :label="
                      !$language
                        ? elementTypeCased.concat(' Subject ')
                        : elementTypeCased.concat(' সাবজেক্ট ')
                    "
                    :placeholder="
                      !$language
                        ? 'Write your email subject..'
                        : 'ইমেইলের সাবজেক্ট লিখুন'
                    "
                    @focus="titleFocused = true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11" style="position: relative">
                  <quill-editor
                    ref="how-it-works"
                    v-model.trim="text"
                    style="height: 26vh"
                    :options="{
                      ...editorOptions,
                      placeholder: '...............',
                      scrollingContainer: $refs['how-it-works']
                    }"
                    @focus="textFocused = true"
                  ></quill-editor>
                  <div class="quill-label">
                    {{
                      elementType === "email"
                        ? !$language
                          ? `Email Body`
                          : "ইমেইল লিখুন"
                        : !$language
                        ? `Message`
                        : "মেসেজ"
                    }}
                  </div>
                  <div
                    v-if="
                      textFocused === true &&
                      !(ruleBody === true)
                    "
                    style="color: red; font-size: 12px; margin-top: 46px"
                  >
                    {{ruleBody}}
                  </div>
                  <v-row>
                    <v-col></v-col>
                    <v-col
                      cols="8"
                      style="
                        margin-top: 60px;
                        display: flex;
                        justify-content: end;
                      "
                    >
                      <v-btn
                        color="red"
                        width="100px"
                        rounded
                        depressed
                        outlined
                        @click="$router.push('/teachers/sms-email')"
                      >
                        {{ !$language ? "Cancel" : "বাতিল" }}
                      </v-btn>
                      <v-btn
                        :loading="saving"
                        color="primary"
                        width="100px"
                        depressed
                        rounded
                        class="ml-4"
                        @click="postSMSEmail"
                      >
                        {{ !$language ? "Share" : "শেয়ার করুন" }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="screenSize <= 1280">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                class="ma-2"
                outlined
                color="warning"
                @click="$router.push('/teachers/sms-email')"
              >
                {{ !$language ? "Cancel" : "বাতিল" }}
              </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                :disabled="isChecked == 0"
                @click="switchTab"
              >
                {{ !$language ? "Next" : "পরবর্তী ধাপ" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col style="padding-top: 4%; padding-left: 4%">
              <v-row>
                <v-col cols="11">
                  <div
                    v-if="
                      titleFocused === true &&
                        (title.trim().length < 5 || title.trim().length > 100)
                    "
                    style="
                      color: red;
                      font-size: 12px;
                      position: relative;
                      top: 88px;
                      left: 10px;
                    "
                  >
                    {{
                      elementType === "email"
                        ? !$language
                          ? "The subject must be between 5 and 100 character"
                          : "সাবজেক্ট ৫ থেকে ১০০ অক্ষরের মধ্যে হতে হবে"
                        : !$language
                        ? " "
                        : " "
                    }}
                  </div>
                  <v-text-field
                    v-if="elementType === 'email'"
                    v-model.trim="title"
                    outlined
                    auto-grow
                    height="70"
                    clear-icon="close"
                    :label="
                      !$language
                        ? elementTypeCased.concat(' Subject ')
                        : elementTypeCased.concat(' সাবজেক্ট ')
                    "
                    :placeholder="
                      !$language
                        ? 'Write your email subject..'
                        : 'ইমেইলের সাবজেক্ট লিখুন'
                    "
                    @focus="titleFocused = true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11" style="position: relative">
                  <quill-editor
                    ref="how-it-works"
                    v-model.trim="text"
                    style="height: 26vh"
                    :options="{
                      ...editorOptions,
                      placeholder: '.....................',
                      scrollingContainer: $refs['how-it-works']
                    }"
                    @focus="textFocused = true"
                  ></quill-editor>
                  <div class="quill-label">
                    {{
                      elementType === "email"
                        ? !$language
                          ? `Email Body`
                          : "ইমেইল লিখুন"
                        : !$language
                        ? `Message`
                        : "মেসেজ"
                    }}
                  </div>
                  <div
                    v-if="
                      textFocused === true &&
                      !(ruleBody === true)
                    "
                    style="color: red; font-size: 12px; margin-top: 46px"
                  >
                    {{ruleBody}}
                  </div>
                  <v-row>
                    <v-col></v-col>
                    <v-col
                      cols="8"
                      style="
                        margin-top: 60px;
                        display: flex;
                        justify-content: end;
                      "
                    >
                      <!-- <v-btn
                        color="red"
                        width="100px"
                        rounded
                        depressed
                        outlined
                        @click="$router.push('/teachers/sms-email')"
                      >
                        Cancel
                      </v-btn> -->
                      <v-btn
                        color="red"
                        width="100px"
                        rounded
                        depressed
                        outlined
                        @click="tab = 0"
                      >
                        {{ !$language ? "Back" : "ফিরে যান" }}
                      </v-btn>

                      <v-btn
                        color="primary"
                        width="100px"
                        depressed
                        rounded
                        class="ml-4"
                        @click="postSMSEmail"
                      >
                        {{ !$language ? "Share" : "শেয়ার করুন" }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { firstLetterUpperCase } from "@ecf/global/helpers";
import StudentContactCard from "@ecf/modules/sms-and-email/StudentContactCard";
import ContactNotificationService from "#ecf/sms-and-email/services/ContactNotificationService.js";
import { getOwnCourses, getOrgCourses } from "#ecf/courses/actions";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";
import NoContentComponent from "/global/components/NoContentComponent";
import { removeTagsFromString } from "/global/utils/helpers.js";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "CreateSmsAndEmail",
  components: {
    StudentContactCard,
    SubmissionAlertMessage,
    NoContentComponent
  },
  props: {
    elementType: { type: String, required: true }
  },
  mixins: [FieldValidations],
  data() {
    return {
      networkError: false,
      showBannerCondition: false,
      bannerMessage: "",
      receivers_list: [],
      receiver_object: {},
      requestObject: {
        message_body: null,
        subject: this.title,
        message_type: this.elementType,
        is_send_to_guardian: false,
        is_send_to_student: false,
        receivers: this.receivers_list
      },
      isChecked: 0,
      tab: 0,
      courses: {},
      course_info: {},
      batches: {},
      batch_info: {},
      students: {},
      student_info: {},
      textFocused: false,
      titleFocused: false,
      editing: false,
      renderNow: false,
      courseIdForBatch: {},
      courseIdVsPartnerCode: {},
      announcementId: undefined,
      title: "",
      text: "",
      editorOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        }
      },
      selectedBatches: [],
      selectedCourse: [],
      student: false,
      guardian: false,
      selectedItems: {},
      only_batches: {},
      only_courses: {},
      selectedStudentCount: {},
      active: {},
      activeBatch: {},
      saving: false
    };
  },
  computed: {
    elementTypeCased() {
      return this.elementType === "email"
        ? !this.$language
          ? firstLetterUpperCase(this.elementType)
          : "ইমেইল"
        : !this.$language
        ? this.elementType.toUpperCase()
        : "এসএমএস";
    },
    canSendCondtition() {
      if (this.elementType === "email") {
        if (this.title?.trim().length < 5 || this.title?.trim().length > 100)
          return false;
      }
      return !(this.text?.trim().length < 12 || this.text?.trim().length > 600);
    },
    totalBatches() {
      return this.batches.length;
    },
    totalStudents() {
      return this.students.length;
    },
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    noContentMessage() {
      return !this.$language
        ? `You can not create ${this.elementType} right now.`
        : `এই মুহূর্তে আপনি ${this.elementType} পাঠাতে পারবেন না।`;
    },
    textLength() {
      return removeTagsFromString(this.text).length;
    },
    ruleBody(){
      return this.betweenValue(this.textLength, 12, 600, true, `${this.elementType.toUpperCase()} body`)
    }
  },
  watch: {},
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system"
      ]);
      this.networkError = true;
      return;
    }
    await this.setupData();
  },
  methods: {
    async setupData() {
      let own_course_list = await getOwnCourses(this.$store);
      let org_course_list = await getOrgCourses(this.$store);

      org_course_list.forEach(async element => {
        element.is_organization = true;
        if (!(element.course_id in this.batches)) {
          await this.$store.dispatch("batch/list", {
            courseId: element.course_id,
            partner_code: element.partner_code
          });
          let items = await this.$store.getters["batch/batches"][
            element.course_id
          ];
          element.num_org_batches = items.length;
        }
      });
      this.courses = [...own_course_list, ...org_course_list];
      for (let item of this.courses) {
        this.course_info[item.course_id] = item;
        this.$set(this.active, item.course_id, false);
      }
    },
    async coursesExpand(id, partner) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        return;
      }
      if (!(id in this.batches)) {
        this.$delete(this.only_courses, id);
        await this.$store.dispatch("batch/list", {
          courseId: id,
          partner_code: partner
        });
        let items = this.$store.getters["batch/batches"][id];
        this.$set(this.batches, id, items);
        this.$set(this.activeBatch, id, {});
        for (let item of items) {
          this.batch_info[item.batch_id] = item;
          // this.selectedStudentCount[item.batch_id] = 0
          this.$set(this.selectedStudentCount, item.batch_id, 0);
          this.$set(this.activeBatch[id], item.batch_id, this.active[id]);
          if (this.active[id]) {
            this.$set(
              this.only_batches,
              `${item.batch_id}#${item.course_id}`,
              true
            );
          }
        }
      }
    },
    async batchExpand(id, course, partner) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        return;
      }
      if (!(course in this.students)) {
        this.$set(this.students, course, {});
      }
      if (!(id in this.students[course])) {
        this.$delete(this.only_batches, `${id}#${course}`);
        let items = await this.$store.dispatch("batch/getStudentInformation", {
          partner_code: partner,
          course_Id: course,
          batch_Id: id
        });
        this.$set(
          this.batch_info[id],
          "num_students",
          Object.keys(items).length
        );
        this.$set(this.students[course], id, Object.keys(items));
        this.student_info = { ...this.student_info, ...items };
        // if (this.active[course] || this.activeBatch[course][id]) {
        if (this.activeBatch[course][id]) {
          for (let student_item of this.students[course][id]) {
            this.$set(
              this.selectedItems,
              `${student_item}#${id}#${course}`,
              true
            );
            this.selectedStudentCount[id] += 1;
            this.isChecked += 1;
          }
        }
      }
    },
    async postSMSEmail() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        return;
      }
      if (!this.canSendCondtition) {
        this.textFocused = true;
        this.titleFocused = true;
        this.bannerMessage =
          this.elementType === "email"
            ? "Subject and Body did not meet condition"
            : "Body did not meet condition";
        if (!this.showBannerCondition) this.showBannerCondition = true;
        else {
          await this.$refs.banner.flash();
        }
        return;
      }
      if (this.saving) return;
      let success = false;
      try {
        this.saving = true;
        this.requestObject.message_body = this.removeHtmlTag(this.text);
        this.requestObject.subject = this.title;

        let studentItems = Object.keys(this.selectedItems);
        let receivers = [];
        for (let item of studentItems) {
          let elems = item.split("#");
          let batchName = this.batches[elems[2]][
            this.batches[elems[2]].indexOf(elems[1])
          ];
          let data = {
            student_id: elems[0],
            student_name: this.student_info[elems[0]].name,
            batch_id: elems[1],
            batch_name: this.batch_info[elems[1]].batch_title,
            course_id: elems[2],
            course_name: this.course_info[elems[2]].course_title
          };
          if ("guardian" in this.student_info[elems[0]]) {
            data["guardian"] = this.student_info[elems[0]].guardian
              .map(item =>
                this.elementType === "sms" ? item.phone : item.email
              )
              .filter(item => item.length > 0);
          }
          if (this.elementType === "sms") {
            data["contact"] = this.student_info[elems[0]].phone;
          } else {
            data["contact"] = this.student_info[elems[0]].email;
          }
          receivers.push(data);
        }
        let course_only_items = Object.keys(this.only_courses);
        for (let item of course_only_items) {
          receivers.push({
            course_id: item
          });
        }
        let batch_only_items = Object.keys(this.only_batches);
        for (let item of batch_only_items) {
          let elems = item.split("#");
          receivers.push({
            course_id: elems[1],
            batch_id: elems[0]
          });
        }
        this.requestObject.receivers = receivers;

        let results = await ContactNotificationService.sendBulk(
          this.$store.state.user.user.last_partner_code,
          this.requestObject
        );
        this.$root.$emit("alert", [
          "Success",
          `${
            this.elementType == "sms" ? "SMS" : "Email"
          }(s) delivered successfully`
        ]);
        success = true;
      } catch (e) {
        // this.$root.$emit("alert", [undefined, e.message]);
        this.bannerMessage = e.message;
        if (!this.showBannerCondition) this.showBannerCondition = true;
        else {
          await this.$refs.banner.flash();
        }
      } finally {
        this.saving = false;
        if (success) this.$router.push("/teachers/sms-email");
      }
    },

    handleInsertion(payload) {
      let elems = payload.split("#"); //(student_id)#(batch_id)#(course_id)
      this.$delete(this.only_batches, `${elems[1]}#${elems[2]}`);
      this.$delete(this.only_courses, elems[2]);
      if (payload in this.selectedItems) {
        this.$delete(this.selectedItems, payload);
        this.selectedStudentCount[elems[1]] -= 1;
        this.isChecked -= 1;
      } else {
        this.$set(this.selectedItems, payload, true);
        this.selectedStudentCount[elems[1]] += 1;
        this.isChecked += 1;
      }
    },
    batchSelection(batch_id, course_id) {
      if (this.studentCondition(course_id, batch_id)) {
        // this.activeBatch[course_id][batch_id] = !this.activeBatch[course_id][batch_id];
        if (this.activeBatch[course_id][batch_id]) {
          for (let student_item of this.students[course_id][batch_id]) {
            let selected_student_string = `${student_item}#${batch_id}#${course_id}`;
            if (!(selected_student_string in this.selectedItems)) {
              this.$set(this.selectedItems, selected_student_string, true);
              this.selectedStudentCount[batch_id] += 1;
              this.isChecked += 1;
            }
            // this.$set(
            //   this.selectedItems,
            //   `${student_item}#${batch_id}#${course_id}`,
            //   true,
            // );
            // this.selectedStudentCount[batch_id] += 1;
            // this.isChecked += 1;
          }
        } else {
          for (let student_item of this.students[course_id][batch_id]) {
            let selected_student_string = `${student_item}#${batch_id}#${course_id}`;
            if (selected_student_string in this.selectedItems) {
              this.$delete(this.selectedItems, selected_student_string);
              this.selectedStudentCount[batch_id] -= 1;
              this.isChecked -= 1;
            }
            // this.$delete(
            //   this.selectedItems,
            //   `${student_item}#${batch_id}#${course_id}`,
            // );
            // this.selectedStudentCount[batch_id] -= 1;
            // this.isChecked -= 1;
          }
        }
        return;
      } else {
        let item = `${batch_id}#${course_id}`;
        if (item in this.only_batches) {
          this.$delete(this.only_batches, item);
          this.isChecked -= 1;
        } else {
          this.$set(this.only_batches, item, true);
          this.isChecked += 1;
        }
      }
    },
    courseSelection(course_id) {
      if (course_id in this.batches) {
        for (let item of this.batches[course_id]) {
          this.activeBatch[item.course_id][item.batch_id] = this.active[
            course_id
          ];
          if (this.studentCondition(item.course_id, item.batch_id)) {
            this.$delete(
              this.only_batches,
              `${item.batch_id}#${item.course_id}`
            );
            if (this.active[course_id]) {
              for (let student_item of this.students[item.course_id][
                item.batch_id
              ]) {
                let selected_student_string = `${student_item}#${item.batch_id}#${item.course_id}`;
                if (!(selected_student_string in this.selectedItems)) {
                  this.$set(this.selectedItems, selected_student_string, true);
                  this.selectedStudentCount[item.batch_id] += 1;
                  this.isChecked += 1;
                }
                // this.$set(
                //   this.selectedItems,
                //   `${student_item}#${item.batch_id}#${item.course_id}`,
                //   true,
                // );
                // this.selectedStudentCount[item.batch_id] += 1;
                // this.isChecked += 1;
              }
            } else {
              for (let student_item of this.students[item.course_id][
                item.batch_id
              ]) {
                let selected_student_string = `${student_item}#${item.batch_id}#${item.course_id}`;
                if (selected_student_string in this.selectedItems) {
                  this.$delete(this.selectedItems, selected_student_string);
                  this.selectedStudentCount[item.batch_id] -= 1;
                  this.isChecked -= 1;
                }
                // this.$delete(
                //   this.selectedItems,
                //   `${student_item}#${item.batch_id}#${item.course_id}`,
                // );
                // this.selectedStudentCount[item.batch_id] -= 1;
                // this.isChecked -= 1;
              }
            }
          } else {
            if (this.activeBatch[item.course_id][item.batch_id]) {
              this.$set(
                this.only_batches,
                `${item.batch_id}#${item.course_id}`,
                true
              );
            } else {
              this.$delete(
                this.only_batches,
                `${item.batch_id}#${item.course_id}`
              );
            }
            // this.$set(
            //   this.only_batches,
            //   `${item.batch_id}#${item.course_id}`,
            //   true,
            // );
          }
        }
        return;
      } else {
        if (course_id in this.only_courses) {
          this.$delete(this.only_courses, course_id);
          this.isChecked -= 1;
        } else {
          this.$set(this.only_courses, course_id, true);
          this.isChecked += 1;
        }
      }
    },
    switchTab() {
      this.tab = this.tab + 1;
    },
    removeHtmlTag(htmlData) {
      let div = document.createElement("div");
      div.innerHTML = htmlData;
      return div.textContent || div.innerText || "";
    },
    studentCondition(course, batch) {
      if (course in this.students) {
        return batch in this.students[course];
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.quill-label {
  position: absolute;
  top: 0px;
  left: 20px;
  background-color: white;
  padding: 4px 6px 0px;
  font-size: 0.7rem;
  color: #787878;
}
</style>
